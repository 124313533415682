import React, { FunctionComponent } from "react";
import { Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import BlockContent from "@sanity/block-content-to-react";
import { ImageInterface, PortableText, TagV2Interface } from "../../types/SanityTypes";
import sanityImageUrlBuilder from "../../utils/imageUrlBuilder";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { useLocation } from '@reach/router';
// @ts-ignore
import "./styles.scss";
import { Link } from "../Link";
import Heading from "../Heading";
import { getLocalizedPath } from "../../utils/gatsbyBuild";
import { Translations } from "../../utils/algoliaProductsQuery";

type ProductLandingTeaserInterface = {
  _rawImage: ImageInterface;
  headline: {
    primaryText: string;
    secondaryText?: string;
    tertiaryText?: string;
  };
  theme?: {
    name: string;
  };
  _rawIntroduction?: PortableText;
  ctaLabel: string;
  ctaReference?: {
    _id?: string;
    i18n_lang?: string;
    slug?: {
      current?: string;
    };
  };
  productLandingPageSlug: string;
  imageAlignment: string;
  filters?: Array<TagV2Interface>;
};

const ProductLandingTeaser: FunctionComponent<ProductLandingTeaserInterface> = props => {
  const { headline, _rawImage, _rawIntroduction, ctaLabel, ctaReference, imageAlignment } = props;
  const location = useLocation();
  const refinements = location?.pathname;

  const createThemeClassName = (className: string) => `theme--${className?.replace(" ", "").toLocaleLowerCase()}`;

  const getCtaURL = () => {
    return `/${Translations[ctaReference?.i18n_lang].products}/${ctaReference?.slug?.current}/`;
  };

  const localizedCtaUrl = getLocalizedPath(getCtaURL(), ctaReference?._id);

  let queryData = "";
  if (refinements.includes(`/${Translations[ctaReference?.i18n_lang].men}`)) {
    queryData = `?refinementList%5Bgender%5D%5B0%5D=${
      Translations[ctaReference?.i18n_lang].men
    }&page=1&configure%5Bfilters%5D=lang%3A${
      ctaReference?.i18n_lang
    }%20AND%20pageType%3AproductV2%20AND%20range%3A${ctaReference?.slug?.current?.replace(
      "-es",
      ""
    )}%20AND%20gender%3A%20${Translations[ctaReference?.i18n_lang].men}`;
  } else if (refinements.includes(`/${Translations[ctaReference?.i18n_lang].women}`)) {
    queryData = `?refinementList%5Bgender%5D%5B0%5D=${
      Translations[ctaReference?.i18n_lang].women
    }&page=1&configure%5Bfilters%5D=lang%3A${
      ctaReference?.i18n_lang
    }%20AND%20pageType%3AproductV2%20AND%20range%3A${ctaReference?.slug?.current?.replace(
      "-es",
      ""
    )}%20AND%20gender%3A%20${Translations[ctaReference?.i18n_lang].women}`;
  } else {
    queryData = ``;
  }
  const { primaryText, secondaryText, tertiaryText } = headline;
  const { sanityId, sanityDataset } = useSiteMetadata();
  const urlBuilder = sanityImageUrlBuilder({
    projectId: sanityId,
    dataset: sanityDataset
  });

  const renderTeaserImage = () => {
    return (
      _rawImage && (
        <picture className="placeholder">
          <source
            media={"(min-width: 768px)"}
            srcSet={
              urlBuilder
                .image(_rawImage as ImageInterface)
                .auto("format")
                .quality(80)
                .width(500)
                .height(500)
                .format("webp")
                .url() || undefined
            }
          />
          <source
            media={"(min-width: 500px)"}
            srcSet={
              urlBuilder
                .image(_rawImage as ImageInterface)
                .auto("format")
                .quality(80)
                .width(718)
                .height(718)
                .format("webp")
                .url() || undefined
            }
          />
          <img
            src={
              urlBuilder
                .image(_rawImage as ImageInterface)
                .auto("format")
                .quality(80)
                .width(400)
                .height(400)
                .format("webp")
                .url() || undefined
            }
            alt={(_rawImage as ImageInterface).alt as string}
            className="img-fluid"
            loading={"lazy"}
          />
        </picture>
      )
    );
  };

  const renderCopy = () => {
    return (
      <div className="rx-landing--teaser-copy">
        <Heading
          cssClass="rx-landing--teaser-title"
          headingLevel="h3"
          ariaLabel={primaryText + " " + (secondaryText || "") + " " + (tertiaryText || "")}
        >
          {primaryText && <span>{primaryText}</span>}
          {secondaryText && <span>{secondaryText}</span>}
          {tertiaryText && { tertiaryText }}
        </Heading>
        <div className="rx-landing--teaser-desc">{_rawIntroduction && <BlockContent blocks={_rawIntroduction} />}</div>
        <Link className="rx-landing--teaser-cta" to={`${localizedCtaUrl}${queryData}`}>
          {ctaLabel}
        </Link>
      </div>
    );
  };

  const isImageLeftAligned = () => {
    return imageAlignment.toLowerCase() === "left";
  };

  return (
    <section
      className={`rx-landing--section rx-landing--teaser rx-landing--teaser-${
        imageAlignment && imageAlignment !== "" ? imageAlignment.toLowerCase() : "default"
      }
      ${props.theme && createThemeClassName(props.theme.name)}`}
    >
      <Container fluid>
        <Row>
          <Col lg={{ span: 1 }}></Col>
          <Col lg="5">{isImageLeftAligned() ? renderTeaserImage() : renderCopy()}</Col>
          <Col lg={{ span: 5 }}>{isImageLeftAligned() ? renderCopy() : renderTeaserImage()}</Col>
          <Col lg={{ span: 1 }}></Col>
        </Row>
      </Container>
    </section>
  );
};

export default ProductLandingTeaser;
