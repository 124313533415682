import React, { FunctionComponent } from "react";
// import "./styles.scss";

const Heading: FunctionComponent<HeadingInterface> = ({ headingLevel, children, cssClass = "", text = "", ariaLabel = "" }) => {
    const Heading = headingLevel;

    return (
        <Heading className={cssClass} aria-label={ariaLabel}>{text || children}</Heading>
    )
};

export default Heading;

interface HeadingInterface {
    headingLevel: string;
    children?: any;
    cssClass?: string;
    text?: string;
    ariaLabel?: string;
}
